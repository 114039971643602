import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../components/Input";
import icon_requestPrice from "./../../assets/svg/request-price_icon-products.svg";
import icon_addToBasket from "./../../assets/svg/add-to-basket_icon-products.svg";
import "./style.css";

function References({ data }) {
    const user = useSelector((state) => state.user);

    // Modal demande de prix
    const [mdlRequest, showRequestRef] = useState(false);
    const [descripRequest, setDescripRequest] = useState("");
    const [referenceSelected, setReferenceSelected] = useState("");

    // Msg de réponse
    const [msgSuccessOrError, setMsgSuccessOrError] = useState("");
    const [msgConf, showMsgConf] = useState(false);

    // Form & array
    const [inputQte, setInputQte] = useState([]);
    const [dataDefault, setDataDefault] = useState({
        tableHead: [],
        tableData: [],
        stock: [],
        price: []
    });
    const [dataUsed, setDataUsed] = useState({
        tableHead: [],
        tableData: [],
        stock: [],
        price: []
    });
    const [filters, setFilters] = useState([]);
    const [filterUsed, setFiltersUser] = useState([]);
    const [formData, setFormData] = useState({
        society: "",
        service: "",
        siret: "",
        cellphoneNumber: "",
        phoneNumber: "",
        address_street: "",
        address_city: "",
        address_zip_code: "",
    });

    // Filtrage des data
    useEffect(() => {
        filterDefaultData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, user]);

    function filterDefaultData() {
        let newDataDefault = {
            tableHead: [],
            tableData: [],
            stock: [],
            price: []
        };

        let arrayInput = [];

        // Default
        if (!data.table_ref || data.table_ref.length === 0) {
            setDataDefault(newDataDefault);
            return;
        }

        const table_ref = data.table_ref;

        // Filtrage table-head
        for (let i = 0; i < table_ref[0].length; i++) {
            newDataDefault.tableHead.push(table_ref[0][i]);

            if (user && user.token && user.token !== '' && i === table_ref[0].length - 1) {
                newDataDefault.tableHead.push("Price");
                newDataDefault.tableHead.push("Quantity");
            }
        }

        // Filtrage table-data
        for (let i = 1; i < table_ref.length; i++) {
            let row = [];
            arrayInput.push(1);

            for (let n = 0; n < table_ref[0].length; n++) {
                row.push(table_ref[i][n]);

                // Stock
                if (n === table_ref[0].length - 1) {
                    newDataDefault.price.push(table_ref[i][n + 1] ? table_ref[i][n + 1] : '');
                }

                // Price
                if (n === table_ref[0].length - 1) {
                    newDataDefault.stock.push(table_ref[i][n + 2] ? table_ref[i][n + 2] : '');
                }
            }
            newDataDefault.tableData.push(row);
        }

        // Tableau des filtres
        let arrayfilter = [];

        for (let i = 2; i < (user.token ? newDataDefault.tableHead.length - 2 : newDataDefault.tableHead.length); i++) {
            let array = [newDataDefault.tableHead[i]]

            for (let row of newDataDefault.tableData) {
                array.push(row[i])
            }

            array = array.reduce((acc, value) => {
                if (!acc.includes(value)) {
                    acc.push(value);
                }
                return acc;
            }, []);

            arrayfilter.push(array);
        }

        // Maj state
        setDataDefault(newDataDefault);
        setDataUsed(newDataDefault);
        setFilters(arrayfilter);
        setFiltersUser(Array(parseInt(arrayfilter.length)).fill(0));
        setInputQte(arrayInput);
    }

    async function downloadPlan(id) {
        console.log(id);
        
        const req = await fetch(`https://api.pro-vide.eu/public/plan/${id}`);
        const res = await req.json();

        const a = document.createElement('a');
        a.href = res.plan;
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank')
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    function updateFilters(e, id) {
        let table_data = [];
        let table_price = [];
        let newFilters = [...filterUsed];

        const idx = parseInt(e.target.value);
        newFilters[id] = idx;

        setFiltersUser(newFilters);

        for (let n = 0; n < dataDefault.tableData.length; n++) {
            let checkFilter = true;

            for (let i = 0; i < newFilters.length; i++) {
                if (newFilters[i] === 0) {
                    continue
                }

                if (dataDefault.tableData[n][i + 2] !== filters[i][newFilters[i]]) {
                    checkFilter = false;
                }
            }

            if (checkFilter) {
                table_data.push(dataDefault.tableData[n]);
                table_price.push(dataDefault.price[n]);
            }
        }


        setDataUsed(prev => {
            return {
                tableHead: prev.tableHead,
                tableData: table_data,
                stock: prev.stock,
                price: table_price
            }
        })
    }

    // Formatage prix '31.00000' => '31.00'
    function formatPrice(num) {
        num = parseFloat(num);

        if (Math.floor(num) === num) {
            return num.toString() + ".00";
        } else {
            let fixedNum = num.toFixed(2);
            return fixedNum.split(".")[1] === "00" ? fixedNum.split(".")[0] : fixedNum;
        }
    }

    // Verification de la valeur 'qte'
    function checkQuantity(qte) {
        let num = parseInt(qte, 10);

        if (isFinite(num) && num > 0) {
            return true;
        }
        return false;
    }

    // Mise à jour d'une quantité
    const setQte = (e, idx) => {
        if (!checkQuantity(e.target.value)) {
            return;
        }

        let array = [...inputQte];
        array[idx] = e.target.value;
        setInputQte(array);
    };

    // Ajout d'une reference au panier de l'utilisateur
    const addToBasket = async (idx) => {
        const req = await fetch(`https://api.pro-vide.eu/user/addToCart/${user._id}`, {
            method: "POST",
            headers: { Authorization: `Bearer ${user.token}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                prod_id: data._id,
                name: data.name,
                qte: inputQte[idx],
                image: data?.images[0],
                id: dataDefault.tableData[idx][0],
                price: dataDefault.price[idx],
            }),
        });
        const res = await req.json();

        if (res === true) {
            showMsgConf(dataDefault.tableData[idx][0]);

            setTimeout(() => {
                showMsgConf(false);
            }, 4000);
        }
    };

    const request = async (event) => {
        event.preventDefault();

        let body = user._id ? { _id: user._id } : { ...formData };
        body.request = descripRequest;
        body.typeReq = "dem";
        body.category = "ref";

        try {
            const req = await fetch(`https://api.pro-vide.eu/user/sendRequest`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            });
            const res = await req.json();

            if (res !== true) {
                setMsgSuccessOrError("Une erreur est survenue");
                setTimeout(() => {
                    window.location.reload();
                }, 3500);
                return;
            }
            setMsgSuccessOrError("Demande envoyer avec succès");
            setTimeout(() => {
                setMsgSuccessOrError("");
                setDescripRequest("");
                showRequestRef(false);
                setFormData({
                    society: "",
                    service: "",
                    siret: "",
                    cellphoneNumber: "",
                    phoneNumber: "",
                    address_street: "",
                    address_city: "",
                    address_zip_code: "",
                });
            }, 4000);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return dataDefault && formData &&
        <div className="modal modal_references">
            <div className="filters">
                <label>Filtres :</label>

                <div className="container">
                    {filters.map((filter, id) =>
                        <select key={id} onChange={(e) => updateFilters(e, id)}>
                            {filter.map((value, idx) =>
                                <option key={idx} value={idx}>{value}</option>
                            )}
                        </select>
                    )}
                </div>
            </div>

            <div className="array">
                <div className="data">
                    {/* TH */}
                    <div className="tableHead">
                        {dataUsed.tableHead.length !== 0 &&
                            dataUsed.tableHead.map((th, idx) => (
                                <p key={idx} className={`th_cell${idx === 1 ? " th_schema" : ""}`}>
                                    {th}
                                </p>
                            ))}
                    </div>

                    {/* TR */}
                    {dataUsed.tableData.map((row, idx) => (
                        <div key={idx} className="tableRow">
                            {/* Réferences */}
                            <p className="tr_cell">
                                {row[0]}
                                {dataUsed.stock[idx] && dataUsed.stock[idx] !== '' && dataUsed.stock[idx] > -10000 &&
                                    <span className="in_stock">stock</span>
                                }
                            </p>

                            {/* Schema */}
                            {row[1] !== '' && <button className="btn-schema" onClick={() => downloadPlan(row[1])}>Download</button>}
                            {row[1] === '' && <p className="btn-schema"></p>}

                            {row.map((v, id) => id > 1 && <p key={id} className="th_cell">{v}</p>)}

                            {user && user.token && user.token !== '' &&
                                <>
                                    {/* Price */}
                                    <p className="tr_cell">{dataUsed.price[idx] !== '' && dataUsed.price[idx] !== '0.00000' && formatPrice(dataUsed.price[idx]) + '€'}</p>

                                    {/* Input quantity */}
                                    {dataUsed.stock[idx] && dataUsed.stock[idx] !== '' && dataUsed.stock[idx] > -10000
                                    && dataUsed.price[idx] !== '' && dataUsed.price[idx] !== '0.00000' ?
                                        <input
                                            type="number"
                                            value={inputQte[idx]}
                                            onChange={(e) => setQte(e, idx)}
                                            className="input_qte"
                                        /> :
                                        <p className="tr_cell">{/* TR VIDE */}</p>
                                    }
                                </>
                            }
                        </div>
                    ))}
                </div>

                {user && user.token && user.token !== '' &&
                    <div className="container-btnBuy">
                        <p className="th_cell">{/* TH VIDE */}</p>

                        {dataUsed.tableData.map((ref, idx) => dataUsed.stock[idx] && dataUsed.stock[idx] !== '' && dataUsed.stock[idx] > -10000
                            && dataUsed.price[idx] !== '' && dataUsed.price[idx] !== '0.00000' ?
                            /* Button add_to_basket */
                            <button
                                key={idx}
                                className="btn-buy"
                                onClick={() => addToBasket(idx)}
                            >
                                <img src={icon_addToBasket} alt="Add to basket" />
                            </button> :

                            /* Button request_price */
                            <button
                                key={idx}
                                className="btn-buy"
                                onClick={() => {
                                    showRequestRef(true);
                                    setReferenceSelected(ref[0]);
                                }}
                            >
                                <img src={icon_requestPrice} alt="Request price" />
                                P.O.R
                            </button>

                        )}
                    </div>
                }
            </div>

            {/* Msg confirmation d'ajout au panier */}
            {msgConf && msgConf !== false && (
                <div className="msgConf">
                    <div className="content">
                        <h4>Votre panier a bien été mis à jour</h4>
                        <p>Ajout de "{msgConf}"</p>
                    </div>
                </div>
            )}

            {/* Description du tableau */}
            <p className="descrip_tableRef">{data?.ref_descrip}</p>

            {/* Mdl de demande de prix */}
            {mdlRequest && (
                <section className="requestRef">
                    <div className="content">
                        {msgSuccessOrError === '' &&
                            <>
                                <i onClick={() => showRequestRef(false)} className="fas fa-xmark"></i>

                                <span className="title">Demande de référence</span>

                                <form onSubmit={(event) => request(event)}>
                                    <div className="input_model input_lock">
                                        <label>Référence :</label>
                                        <input type="text" value={referenceSelected} className={`input_`} readOnly />
                                    </div>


                                    {(!user.token || user.token === '') && (
                                        <div className="container_fields">
                                            <Input label="Société" placeholder="JohnDoe company" value={formData.society} setValue={setFormData} labForm="society" />
                                            <Input label="Service" placeholder="Général" value={formData.service} setValue={setFormData} labForm="service" />
                                            <Input label="Siret/Siren" placeholder="Numéro siret/siren" value={formData.siret} setValue={setFormData} labForm="siret" />
                                            <Input label="Téléphone fixe" placeholder="Saisissez votre fixe" value={formData.cellphoneNumber} setValue={setFormData} labForm="cellphoneNumber" />
                                            <Input label="Téléphone mobile" placeholder="Saisissez votre mobile" value={formData.phoneNumber} setValue={setFormData} labForm="phoneNumber" />
                                            <Input label="Adresse" placeholder="10 route de paris" value={formData.address_street} setValue={setFormData} labForm="address_street" />
                                            <Input label="Ville" placeholder="Strasbourg" value={formData.address_city} setValue={setFormData} labForm="address_city" />
                                            <Input label="Code postal" placeholder="67000" value={formData.address_zip_code} setValue={setFormData} labForm="address_zip_code" />
                                        </div>
                                    )}

                                    <div className="input_model input_lock">
                                        <label>Besoins spécifiques :</label>
                                        <textarea
                                            className={`input_`}

                                            value={descripRequest}
                                            placeholder="Décrivez-nous au mieux vos besoins"
                                            onChange={(e) => setDescripRequest(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <button type="submit">Faire une demande</button>
                                </form>
                            </>
                        }

                        {msgSuccessOrError !== '' &&
                            <p>{msgSuccessOrError}</p>
                        }
                    </div>
                </section>
            )}
        </div>
}

export default References;

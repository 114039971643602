import { useSelector } from "react-redux";
import "./style.css"

const title = {
    fr: `Description`,
    en: `Description`,
    sp: `Descripción`,
    de: `Beschreibung`
};

function Description({ data }) {    
    const lang = useSelector(state => state.menu.lang);

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };
    
    return (
        <div className='modal modal_description'>
            <h4>{title[lang]}</h4>
            <p>{transformText(data)}</p>
        </div>
    )
}

export default Description;